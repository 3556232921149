import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import TwoColumnSection from "../components/TwoColumnSection";

import IconGlobe from "../images/inlinesvgs/icons/globe.svg";
import IconAddressBook from "../images/inlinesvgs/icons/address-book.svg";
import IconStar from "../images/inlinesvgs/icons/star.svg";
import IconSearch from "../images/inlinesvgs/icons/search.svg";
import IconBracketsCurly from "../images/inlinesvgs/icons/brackets-curly.svg";
import IconKey from "../images/inlinesvgs/icons/key.svg";

// import IconPromotional from "../images/inlinesvgs/sidemail-hp-icons_promotional.svg";

const Page = ({ data }) => (
	<Layout
		pageMeta={{
			title: "Targeted email marketing – Sidemail",
		}}>
		<div>
			<section className="aboveFold">
				<div className="container">
					{/* <div className="mb-30"> */}
					{/* 	<IconPromotional width={90} height={90} /> */}
					{/* </div> */}

					<h1>
						<div className="text-xsmall uptitle mb-20 text-light">
							Targeted email marketing
						</div>
						<div>Send product updates & newsletters</div>
					</h1>

					<p className="maxWidth-750 text-large text-dark itemCenter mb-30">
						Send product update emails (newsletters) and let your users know
						what’s new in your product with Messenger. It’s as easy as sending
						an email directly from your Gmail account, except you can send to
						all users and see the statistics.
					</p>

					<a
						className="button button--bright button--huge mb-70"
						href="https://client.sidemail.io/register"
						style={{ minWidth: 300 }}>
						Start 7-day trial
					</a>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/messenger.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>

			<section className="container">
				<div className="twoColumns">
					<div className="twoColumns-text">
						<h2 className="title-large">Compose your message</h2>

						<p className="text">
							Create personal, content-focused emails with an efficient and
							pleasure-to-use text editor.
						</p>

						<h3 className="uptitle text-xsmall text-light">
							This is perfect for
						</h3>

						<ul className="bulletList">
							<li>Product updates</li>
							<li>Newsletters</li>
							<li>Personalized messages</li>
						</ul>
					</div>

					<div className="twoColumns-imageWrapper">
						<div className="twoColumns-imageWrapperInner">
							<Img
								fluid={data.richTextEditorImage.childImageSharp.fluid}
								imgStyle={{ objectFit: "unset" }}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Reach the right audience"
					text="Reach the right people at the right time. Filter the recipients based on groups or specific conditions, for example, signup date, plan type, or last seen time."
					list={["Easy-to-use filtering", "Multiple conditions", "Datepicker"]}
					img={data.filtersImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="See what works"
					text="Take out the guesswork and learn what is working and where you can improve. See how your campaigns are performing in real-time, only the metrics you care about, no bloat."
					list={[
						"Deliveries, and open rate",
						"Bounces, complaints, and unsubscribes",
						"See individual messages in sending history",
					]}
					img={data.keyMetricsImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Choose the right time"
					text="They say there's never a perfect time. That's probably because they don't schedule delivery of their emails for the correct hour and based on the recipient's timezone. You will."
					list={["Delayed delivery", "Timezone-based delivery"]}
					img={data.scheduledDeliveryImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Know your audience"
					text="Send users' data to Sidemail via API. Thanks to this, you can target your emails precisely by users' key specifications."
					list={[
						"Unlimited contacts for all plans",
						"Unlimited key-value pairs",
						"Easy-to-use management",
					]}
					cta={<Link to="/contact-profiles">Learn more</Link>}
					img={data.contactProfilesImage.childImageSharp.fluid}
				/>
			</section>

			<section className="moreFeaturesSection container">
				<ul>
					<li className="valuesWrapper mb-20">
						<IconGlobe className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">
								Images served from CDN
							</h4>
							<div className="text-small text-light">
								All images are served from CDN around the globe to ensure the
								fastest loading time possible.
							</div>
						</div>
					</li>

					<li className="valuesWrapper mb-20">
						<IconBracketsCurly className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">
								Easy-to-use variables
							</h4>
							<div className="text-small text-light">
								Add recipient-specific information to your email by using
								variables. Useful for links, dates, amounts.
							</div>
						</div>
					</li>

					<li className="valuesWrapper mb-20">
						<IconSearch className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">Sending history</h4>
							<div className="text-small text-light">
								Get a comprehensive overview of every sent email. See the email
								preview, timeline, status, and more.
							</div>
						</div>
					</li>

					<li className="valuesWrapper mb-20">
						<IconAddressBook className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">CSV import</h4>
							<div className="text-small text-light">
								Import your mailing list by uploading a CSV file and mapping out
								the correct data fields.
							</div>
						</div>
					</li>

					<li className="valuesWrapper mb-20">
						<IconKey className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">Your domain & DKIM</h4>
							<div className="text-small text-light">
								Use your own domain for sending. For the best delivery results,
								we automatically set up DKIM and SPF.
							</div>
						</div>
					</li>

					<li className="valuesWrapper mb-20">
						<IconStar className="valuesIcon" />
						<div className="valuesText">
							<h4 className="text-dark u-text-bold mb-8">
								5-star rated support
							</h4>
							<div className="text-small text-light">
								If needed, you'll get personal help from real people, directly
								from the development team.
							</div>
						</div>
					</li>
				</ul>
			</section>

			<CtaSection />
		</div>
	</Layout>
);

export default Page;

export const query = graphql`
	query {
		noCodeEditorImage: file(
			relativePath: { eq: "sidemail-public--api-nocode.png" }
		) {
			...twoColumnsImage
		}
		richTextEditorImage: file(
			relativePath: { eq: "sidemail-public--onetime-richtext.png" }
		) {
			...twoColumnsImage
		}
		filtersImage: file(
			relativePath: { eq: "sidemail-public--onetime-filters.png" }
		) {
			...twoColumnsImage
		}
		keyMetricsImage: file(
			relativePath: { eq: "sidemail-public--onetime-metrics.png" }
		) {
			...twoColumnsImage
		}
		scheduledDeliveryImage: file(
			relativePath: { eq: "sidemail-public--onetime-schedule.png" }
		) {
			...twoColumnsImage
		}
		contactProfilesImage: file(
			relativePath: { eq: "sidemail-public--automation-contacts.png" }
		) {
			...twoColumnsImage
		}
	}
`;
